
import { Component, Watch } from "vue-property-decorator";
import { IAgencyDB, IMissionDB, IUserDB, LabelValue, MissionPeriod, StoreAction, StoreMutation, TimeSlot } from "@/types";
import { Action, Getter, Mutation, State } from "vuex-class";
import { capitalize, formattedAddress, getFormattedHour } from "@/helpers/commons";
import { JobOfferStatus, ROUTES, WorkerMissionStatus } from "@/data";
import MissionAvailableCommands from "@/components/Mission/MissionAvailableCommands.vue";
import moment from "moment-timezone";
import AgencyPreview from "@/components/AgencyPreview.vue";
import { mixins } from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";
import MissionDetail from "@/views/MissionDetail.vue";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import Table from "@/dsComponents/Table/Table.vue";
import Button from "@/dsComponents/buttons/Button.vue";
import Badge from "@/dsComponents/Badge/Badge.vue";
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import useJobOfferStatusBadge from "../composables/job-offer/useJobOfferStatusBadge";

@Component({
    name: "Mission",
    components: { AgencyPreview, MissionAvailableCommands, MissionDetail, LMap, LTileLayer, LMarker, Table, Button },
})
export default class Mission extends mixins(TitleManager) {
    loading = false;

    globalErrors: any = [];
    mission: IMissionDB | null = null;
    getFormattedHour = getFormattedHour;
    moment = moment;
    capitalize = capitalize;
    agencyToRead: IAgencyDB | null = null;
    pageTitle = "";

    url: string = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

    attribution: string = '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors';

    @State("collaborators") collaborators!: IUserDB[];
    @Getter("businessSectorOptions") businessSectorOptions!: LabelValue[];
    @Getter("jobOptions") jobOptions!: LabelValue[];
    @Action("actions/getMission") getMission!: StoreAction;
    @Action("actions/loadAgency") loadAgency!: StoreAction;
    @Action("actions/loadCompany") loadCompany!: StoreAction;
    @Action("actions/missionViewed") missionViewed!: StoreAction;
    @Mutation("addLink") addLink!: StoreMutation;
    @Mutation("removeLink") removeLink!: StoreMutation;
    @Getter("isAgency") isAgency!: LabelValue[];
    @Getter("isCompany") isCompany!: LabelValue[];
    @Getter("userContext") userContext!: string;

    @Watch("$route.params.missionId")
    onMissionId() {
        this.init();
    }

    get agencyName() {
        return this.mission?.agency?.name;
    }

    get missionId() {
        return this.$route.params.missionId;
    }

    get mapMarkerPosition() {
        if (this.mission?.address?.location) {
            return {
                lat: parseFloat(this.mission.address.location[1].toString()),
                lng: parseFloat(this.mission.address.location[0].toString()),
            };
        }
    }

    get missionName() {
        if (this.mission) {
            if (!this.mission.name) {
                return this.mission.client?.name || "";
            }
            if (this.isAgency) {
                return this.mission.name;
            }
            if (this.isCompany) {
                if (!this.mission.agencyId) {
                    return this.$t("layout.left.newOrder");
                }
                return this.mission.agency?.name || this.$t("layout.left.newOrder");
            }
        } else {
            return null;
        }
    }

    get clientName() {
        return this.mission?.client?.name;
    }

    get detailPage() {
        return { name: ROUTES.APP.MISSION.DETAIL, params: this.$route.params };
    }

    get presentationPage() {
        return { name: ROUTES.APP.MISSION.PRESENTATION, params: this.$route.params };
    }

    get theAddress() {
        return formattedAddress(this.mission?.address);
    }

    get hasPresentation() {
        if (this.isCompany) {
            if (this.mission?.workers?.length) {
                for (let i = 0, len = this.mission.workers.length; i < len; ++i) {
                    let worker = this.mission.workers[i];

                    if (worker) {
                        switch (worker.status) {
                            case WorkerMissionStatus.presented:
                            case WorkerMissionStatus.validated:
                            case WorkerMissionStatus.rejectedByCompany:
                                return true;
                        }
                    }
                }
            }
        }
    }

    get description() {
        return this.mission?.description?.replace(/\n/gim, "<br>") || "";
    }

    get internalAgencyNotes() {
        return this.mission?.internalAgencyNotes?.replace(/\n/gim, "<br>") || "";
    }

    get collaborator() {
        if (this.mission?.companyOwnerId) {
            const collab: IUserDB | undefined = this.mission?.client?.collaborators?.find((c: IUserDB) => c._id === this.mission?.companyOwnerId);

            if (collab) {
                return capitalize(collab.firstname) + " " + capitalize(collab.lastname) + " - " + collab.phone;
            } else {
                return "";
            }
        }
        if (this.mission?.realCompanyId && this.mission.realCompany) {
            let phone = this.mission.client?.phone || "";
            if (phone) {
                return this.$t("page.mission.companyPhone") + " <a target='_blank' href='tel:" + phone + "'>" + phone + "</a>";
            }
        }

        return "";
    }

    get tableColumns() {
        return [
            { field: "name", header: "Nom de l'annonce" },
            { field: "workerToTreated", header: "À traiter", width: "100px" },
            { field: "status", header: "Statut", component: Badge, width: "140px" },
            { field: "createdAt", header: "Créée", width: "120px" },
        ];
    }

    get jobOffer() {
        if (this.mission?.jobOffers) {
            return this.mission.jobOffers.map((jobOffer) => ({
                name: jobOffer.name,
                workerToTreated: jobOffer.workerToTreated || 0,
                createdAt: moment(jobOffer?.createdAt).format(this.$t("date.format")),
                status: useJobOfferStatusBadge(jobOffer.status),
                _id: jobOffer._id,
            }));
        }
        return [];
    }

    get jobOfferActions() {
        return [
            {
                onClick: (jobOffer: any) => {
                    this.$router.push({ name: ROUTES.APP.ATS.JOBOFFER.READ._ROOT, params: { jobOfferId: jobOffer._id } });
                },
                button: {
                    iconOnly: "file-search",
                    type: "neutral",
                    size: "xs",
                    variant: "ghost",
                },
            },
        ];
    }

    numbers(period: MissionPeriod, hours: TimeSlot[]) {
        let nbDays = moment(period.end).diff(moment(period.start), "days") + 1;
        let i = 0;
        let days = 0;
        const d = moment.duration(0);

        while (i < nbDays) {
            const today = moment(period.start).add(i, "day");
            if (period.weekendIncluded || (today.day() !== 0 && today.day() !== 6)) {
                days += 1;
                hours.forEach((hour: TimeSlot) => {
                    let hourToCopy = hour.to;
                    if (hour.to === 0 && hour.from === 0) {
                        hourToCopy = 2400;
                    }
                    let sf: any = hour.from.toString().length === 3 ? "0" + hour.from : hour.from;
                    let st: any = hourToCopy.toString().length === 3 ? "0" + hourToCopy : hourToCopy;
                    let f = sf / 100;
                    let t = st / 100;

                    let td = today.clone();
                    if (hourToCopy < hour.from) {
                        td.add(1, "day");
                    }
                    td.hour(t.toFixed(0)).minute(t.toFixed(2).split(".")[1]);
                    let fd = today.clone();
                    fd.hour(f.toFixed(0)).minute(f.toFixed(2).split(".")[1]);

                    d.add(td.diff(fd));
                });
            }

            ++i;
        }

        let h = d.asHours().toFixed(2).split(".")[0];
        let m = d.asMinutes() - h * 60;
        return /*d.days() + 1*/ days + "j (" + (h ? h + "h" : "") + (m ? m : "") + ")";
    }

    getBusinessSector(id: string) {
        return this.businessSectorOptions.find((i: LabelValue) => i.value === id);
    }

    getJob(id: string | number) {
        return this.jobOptions.find((i: LabelValue) => i.value === id);
    }

    missionChanged(changedMission: IMissionDB) {
        this.mission = changedMission;
        this.$forceUpdate();
    }

    latLng(position: any) {
        return (
            position && {
                lat: parseFloat(<string>position[1].toString()),
                lng: parseFloat(<string>position[0].toString()),
            }
        );
    }

    emitCommand(result: any) {
        this.$emit("missionChanged", result);
    }

    goToClient() {
        if (this.mission?.client) {
            this.$router.push({ name: ROUTES.APP.CLIENT, params: { companyId: this.mission.client._id } });
        }
    }

    openAgencyPreview() {
        this.agencyToRead = this.mission?.agency || null;
    }

    getJobName(slotName: string | number) {
        if (slotName === "all") {
            return "";
        } else {
            return capitalize(this.getJob(slotName)?.label || "");
        }
    }

    async populateAgency() {
        try {
            if (this.mission) {
                this.$set(this.mission, "agency", await this.loadAgency(this.mission.agencyId));

                if (this.mission?.agency?.survey) {
                    const survey: any = this.mission.agency.survey;

                    if (survey) {
                        if (survey.mainBusinessSectors) {
                            survey.mainBusinessSectors = survey.mainBusinessSectors.map((i: string) => this.getBusinessSector(i));
                        }
                        if (survey.developingBussinessSectors) {
                            survey.developingBussinessSectors = survey.developingBussinessSectors.map((i: string) => this.getBusinessSector(i));
                        }
                        if (survey.jobsRequiringMissions) {
                            survey.jobsRequiringMissions = survey.jobsRequiringMissions.map((i: string) => this.getJob(i));
                        }
                        if (survey.jobsRequiringWorkers) {
                            survey.jobsRequiringWorkers = survey.jobsRequiringWorkers.map((i: string) => this.getJob(i));
                        }
                        this.$set(this.mission.agency, "survey", survey);
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    async init() {
        this.loading = true;
        try {
            this.mission = await this.getMission(this.missionId);
            if (this.mission) {
                await this.addLink({
                    label: capitalize(this.mission.name),
                    icon: "bag",
                    route: { name: ROUTES.APP.MISSION.DETAIL, params: { missionId: this.mission._id } },
                    value: this.mission._id,
                });

                if (this.isCompany && this.mission.agencyId) {
                    await this.populateAgency();
                }
                if (this.mission.realCompanyId) {
                    this.mission.realCompany = await this.loadCompany(this.mission.realCompanyId);
                }

                if (this.mission.alerts.find((a: any) => a.kind === "replaceWorkers")) {
                    await this.missionViewed(this.missionId);
                }
            }
        } catch (e) {
            console.log(e);
            const error: { fullErr?: any } = e as { fullErr?: any };
            if (error?.fullErr?.response?.status === 404) {
                this.globalErrors.push({ id: "Mission.NotFound" });
                await this.removeLink(this.missionId);
            }
            if (error?.fullErr?.response?.status === 500) {
                this.globalErrors.push({ id: "Global.ServerError" });
                await this.removeLink(this.missionId);
            }
        }
        this.loading = false;
    }

    beforeMount() {
        this.init();
    }
}
